import { createWebHistory, createRouter } from "vue-router";
import SignIn from "@/components/auth/SignIn.vue";

const authGuard = (to, from, next) => {
  var isAuthenticated = false;
  let user = JSON.parse(localStorage.getItem("user"));
  if (user.access_token) isAuthenticated = true;
  else isAuthenticated = false;
  if (isAuthenticated) next();
  else next("/login");
};

const routes = [
  { path: "/", component: SignIn, meta: { title: "Sign In" } },
  { path: "/login", component: SignIn, meta: { title: "Sign In" } },
  {
    path: "/forget",
    component: () => import("@/components/auth/Forget.vue"),
    beforeEnter: authGuard,
    meta: { title: "Forget Credentials" },
  },
  {
    path: "/landing-page",
    component: () => import("@/components/LandingPage.vue"),
    beforeEnter: authGuard,
    meta: { title: "Landing Page" },
  },
  //Transponder
  {
    path: "/dashboard",
    component: () => import("@/components/transponder/TransDashboard.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transponder Dashboard" },
  },
  //warehouse one
  {
    path: "/inventory",
    component: () =>
      import("@/components/transponder/warehouse one/Inventory.vue"),
    beforeEnter: authGuard,
    meta: { title: "Received Transponders", back: "clients" },
  },
  {
    path: "/clients-inventory",
    component: () =>
      import("@/components/transponder/warehouse one/ClientInventory.vue"),
    beforeEnter: authGuard,
    meta: { title: "Received Transponders", back: "clients" },
  },
  {
    path: "/pre-orders",
    component: () =>
      import("@/components/transponder/warehouse one/AgencyReceivedOrders.vue"),
    beforeEnter: authGuard,
    meta: { title: "Received Transponders", back: "clients" },
  },
  {
    path: "/client-orders",
    component: () =>
      import("@/components/transponder/warehouse one/ClientReceivedOrders.vue"),
    beforeEnter: authGuard,
    meta: { title: "Received Transponders", back: "clients" },
  },
  {
    path: "/transponder-clients",
    component: () =>
      import("@/components/transponder/warehouse one/Clients.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients", back: "landing-page" },
  },
  {
    path: "/client-agencies",
    component: () =>
      import("@/components/transponder/warehouse one/ClientAgencies.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients Agencies", back: "clients" },
  },
  {
    path: "/accounts",
    component: () =>
      import("@/components/transponder/warehouse one/Accounts.vue"),
    beforeEnter: authGuard,
    meta: { title: "Client-Accounts", back: "clients" },
  },
  {
    path: "/status",
    component: () =>
      import("@/components/transponder/warehouse one/TransponderStatus.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transponder Status", back: "clients" },
  },
  {
    path: "/sub-clients",
    component: () =>
      import("@/components/transponder/warehouse one/SubClients.vue"),
    beforeEnter: authGuard,
    meta: { title: "SubClients", back: "clients" },
  },
  {
    path: "/all-transponders",
    component: () =>
      import("@/components/transponder/Ship/TransponderLocation.vue"),
    beforeEnter: authGuard,
    meta: { title: "Search Transponder Location" },
  },
  // warehouse two
  {
    path: "/clients-two",
    component: () =>
      import("@/components/transponder/warehouse two/Clients.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients", back: "landing-page" },
  },
  {
    path: "/single-transponders",
    component: () =>
      import("@/components/transponder/warehouse two/Inventory.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients", back: "clients-two" },
  },
  {
    path: "/client-inventory",
    component: () =>
      import("@/components/transponder/warehouse two/ClientInventory.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients", back: "clients-two" },
  },
  //open-orders
  {
    path: "/openroads-open-orders",
    component: () =>
      import("@/components/transponder/open-orders/OpenroadsOrders.vue"),
    beforeEnter: authGuard,
    meta: { title: "Client Transponder Fulfillments", back: "clients-two" },
  },
  {
    path: "/assign-transponders",
    component: () =>
      import("@/components/transponder/open-orders/AssignTransponders.vue"),
    beforeEnter: authGuard,
    meta: { title: "Client Transponder Fulfillments", back: "clients-two" },
  },
  {
    path: "/assign-batch",
    component: () =>
      import(
        "@/components/transponder/open-orders/AssignTranspondersBatch.vue"
      ),
    beforeEnter: authGuard,
    meta: { title: "Client Transponder Fulfillments", back: "clients-two" },
  },
  {
    path: "/crm-open-orders",
    component: () =>
      import("@/components/transponder/open-orders/CRMOpenOrders.vue"),
    beforeEnter: authGuard,
    meta: { title: "Client Transponder Fulfillments", back: "clients-two" },
  },
  {
    path: "/batch-request",
    component: () =>
      import("@/components/transponder/open-orders/BatchRequests.vue"),
    beforeEnter: authGuard,
    meta: { title: "Client Transponder Fulfillments", back: "clients-two" },
  },
  //pick module
  {
    path: "/pick",
    component: () =>
      import("@/components/transponder/Pick/PickTransponders.vue"),
    beforeEnter: authGuard,
    meta: { title: "Client Transponder Fulfillments", back: "clients-two" },
  },
  //pack module
  {
    path: "/pack",
    component: () =>
      import("@/components/transponder/Pack/PackTransponders.vue"),
    beforeEnter: authGuard,
    meta: { title: "Client Transponder Fulfillments", back: "clients-two" },
  },
  //Ship Module
  {
    path: "/ship",
    component: () =>
      import("@/components/transponder/Ship/ShipTransponders.vue"),
    beforeEnter: authGuard,
    meta: { title: "Client Transponder Fulfillments", back: "clients-two" },
  },
  {
    path: "/shipped",
    component: () =>
      import("@/components/transponder/Ship/ShippedTransponders.vue"),
    beforeEnter: authGuard,
    meta: { title: "Client Transponder Fulfillments", back: "clients-two" },
  },
  {
    path: "/single-ship",
    component: () =>
      import("@/components/transponder/Ship/SingleShipTransponders.vue"),
    beforeEnter: authGuard,
    meta: { title: "Client Transponder Fulfillments", back: "clients-two" },
  },
  {
    path: "/single-shipped",
    component: () =>
      import("@/components/transponder/Ship/SingleShippedTransponders.vue"),
    beforeEnter: authGuard,
    meta: { title: "Client Transponder Fulfillments", back: "clients-two" },
  },
  {
    path: "/all-shipped",
    component: () =>
      import("@/components/transponder/Ship/AllShippedTransponders.vue"),
    beforeEnter: authGuard,
    meta: { title: "Client Transponder Fulfillments", back: "clients-two" },
  },
  //RMA module
  {
    path: "/return-materials",
    component: () => import("@/components/transponder/RMA/RMADashboard.vue"),
    beforeEnter: authGuard,
    meta: { title: "Client Transponder Fulfillments", back: "clients-two" },
  },
  // virtual transponders
  {
    path: "/virtual-warehouse",
    component: () =>
      import("@/components/transponder/virtualTransponders/warehouseTwo.vue"),
    beforeEnter: authGuard,
    meta: { title: "Client Transponder Fulfillments", back: "clients-two" },
  },
  {
    path: "/virtual-shipped",
    component: () =>
      import(
        "@/components/transponder/virtualTransponders/ShippedTransponders.vue"
      ),
    beforeEnter: authGuard,
    meta: { title: "Client Transponder Fulfillments", back: "clients-two" },
  },

  //client Mangement
  {
    path: "/client-management",
    component: () => import("@/components/client/Clients.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients Management" },
  },
  {
    path: "/carrier-management",
    component: () => import("@/components/client/CarrierClients.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients Management" },
  },
  {
    path: "/client-profile",
    component: () => import("@/components/client/Profile.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients Management" },
  },
  {
    path: "/carrier-profile",
    component: () => import("@/components/client/CarrierProfile.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients Management" },
  },
  {
    path: "/client-users",
    component: () => import("@/components/client/SystemUsers.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients Management" },
  },
  {
    path: "/client-groups",
    component: () => import("@/components/client/Group.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients Management" },
  },
  {
    path: "/client-subsidiaries",
    component: () => import("@/components/client/Subsidiaries.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients Management" },
  },
  {
    path: "/client-depts",
    component: () => import("@/components/client/Departments.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients Management" },
  },
  {
    path: "/ticket-priorities",
    component: () => import("@/components/ticketsystem/TicketPriorities.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients Management" },
  },
  {
    path: "/view-request",
    component: () => import("@/components/ticketsystem/ViewRequest.vue"),
    beforeEnter: authGuard,
    meta: { title: "View Project" },
  },
  {
    path: "/view-ticket",
    component: () => import("@/components/ticketsystem/ViewTicket.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients Management" },
  },
  {
    path: "/requirements",
    component: () => import("@/components/ticketsystem/Requirements.vue"),
    beforeEnter: authGuard,
    meta: { title: "Tasks" },
  },
  {
    path: "/view-requirement",
    component: () => import("@/components/ticketsystem/ViewRequirement.vue"),
    beforeEnter: authGuard,
    meta: { title: "View Task" },
  },
  {
    path: "/client-signups",
    component: () => import("@/components/client/Signups.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients Management" },
  },
  {
    path: "/client-categories",
    component: () => import("@/components/client/Categories.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients Management" },
  },
  {
    path: "/departments",
    component: () => import("@/components/ticketsystem/Departments.vue"),
    beforeEnter: authGuard,
    meta: { title: "Departments Management" },
  },
  {
    path: "/change-requests",
    component: () => import("@/components/ticketsystem/ChangeRequests.vue"),
    beforeEnter: authGuard,
    meta: { title: "Requests Management" },
  },
  {
    path: "/add-client",
    component: () => import("@/components/client/NewClient.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients Management" },
  },
  {
    path: "/add-project",
    component: () => import("@/components/ticketsystem/NewProject.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients Management" },
  },

  {
    path: "/project-category",
    component: () => import("@/components/ticketsystem/ProjectLanding.vue"),
    beforeEnter: authGuard,
    meta: { title: "Project Category" },
  },

  //transaction Mangement
  {
    path: "/carrier-transactions",
    component: () => import("@/components/transaction/CarrierTransactions.vue"),
    beforeEnter: authGuard,
    meta: { title: "Carrier Transactions" },
  },
  {
    path: "/disputed-carrier-transactions",
    component: () =>
      import("@/components/transaction/DisputedCarrierTransactions.vue"),
    beforeEnter: authGuard,
    meta: { title: "Disputed Carrier Transactions" },
  },
  {
    path: "/disputed-transactions",
    component: () => import("@/components/transaction/dispute.vue"),
    beforeEnter: authGuard,
    meta: { title: "Disputed Transactions" },
  },
  {
    path: "/enterprise-transactions",
    component: () =>
      import("@/components/transaction/EnterpriseTransactions.vue"),
    beforeEnter: authGuard,
    meta: { title: "Enterprise Transactions" },
  },
  {
    path: "/enterprise-transaction-dumps",
    component: () =>
      import("@/components/transaction/EnterpriseTransactionDumps.vue"),
    beforeEnter: authGuard,
    meta: { title: "Enterprise Transaction Dumps" },
  },
  {
    path: "/carrier-transaction-dumps",
    component: () =>
      import("@/components/transaction/CarrierTransactionDumps.vue"),
    beforeEnter: authGuard,
    meta: { title: "Carrier Transaction Dumps" },
  },
  {
    path: "/failed-replenish",
    component: () => import("@/components/transaction/Replenish.vue"),
    beforeEnter: authGuard,
    meta: { title: "Failed Credit Card Replenish" },
  },
  {
    path: "/enterprise-dumps",
    component: () => import("@/components/transaction/EnterpriseDumps.vue"),
    beforeEnter: authGuard,
    meta: { title: "Enterprise Dumps" },
  },
  {
    path: "/carrier-dumps",
    component: () => import("@/components/transaction/CarrierDumps.vue"),
    beforeEnter: authGuard,
    meta: { title: "Carrier Dumps" },
  },
  {
    path: "/citations",
    component: () => import("@/components/transaction/Citations.vue"),
    beforeEnter: authGuard,
    meta: { title: "Citations" },
  },
  {
    path: "/monthly-invoices",
    component: () => import("@/components/transaction/MonthlyInvoices.vue"),
    beforeEnter: authGuard,
    meta: { title: "Monthly Invoices" },
  },
  {
    path: "/weekly-invoices",
    component: () => import("@/components/transaction/WeeklyInvoices.vue"),
    beforeEnter: authGuard,
    meta: { title: "Weekly Invoices" },
  },
  {
    path: "/weekly-report",
    component: () => import("@/components/transaction/WeeklyReport.vue"),
    beforeEnter: authGuard,
    meta: { title: "Weekly Reports" },
  },
  {
    path: "/balance",
    component: () => import("@/components/transaction/Accounts.vue"),
    beforeEnter: authGuard,
    meta: { title: "Account Balance Management" },
  },
  {
    path: "/fulfillments",
    component: () => import("@/components/transaction/Fulfillments.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transponder Fulfillments" },
  },
  {
    path: "/single-request",
    component: () => import("@/components/transaction/SingleRequest.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transponder Request" },
  },
  {
    path: "/card-details",
    component: () => import("@/components/transaction/CardDetails.vue"),
    beforeEnter: authGuard,
    meta: { title: "Payment Card Management" },
  },
  {
    path: "/top-ups",
    component: () => import("@/components/transaction/TopUpRequests.vue"),
    beforeEnter: authGuard,
    meta: { title: "Top Up Requests" },
  },
  {
    path: "/carrier-single-request",
    component: () =>
      import("@/components/transaction/CarrierSingleRequest.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transponder Request" },
  },
  {
    path: "/carrier-card-details",
    component: () => import("@/components/transaction/CarrierCardDetails.vue"),
    beforeEnter: authGuard,
    meta: { title: "Payment Card Management" },
  },

  {
    path: "/carrier-invoices",
    component: () => import("@/components/transaction/CarrierInvoices.vue"),
    beforeEnter: authGuard,
    meta: { title: "Carrier Invoices Management" },
  },
  {
    path: "/incoming-invoices",
    component: () => import("@/components/transaction/IncomingInvoices.vue"),
    beforeEnter: authGuard,
    meta: { title: "Carrier Invoices Management" },
  },
  {
    path: "/carrier-citations",
    component: () => import("@/components/transaction/CarrierCitations.vue"),
    beforeEnter: authGuard,
    meta: { title: "Carrier Citations Management" },
  },

  //vehicle management
  {
    path: "/vehicle-management",
    component: () => import("@/components/vehicle/Vehicle.vue"),
    beforeEnter: authGuard,
    meta: { title: "Vehicle Management", back: "client-vehicle" },
  },
  {
    path: "/carrier-vehicles",
    component: () => import("@/components/vehicle/CarrierVehicle.vue"),
    beforeEnter: authGuard,
    meta: { title: "Vehicle Management", back: "client-vehicle" },
  },
  {
    path: "/carrier-specific-vehicles",
    component: () => import("@/components/vehicle/CarrierSpecificVehicle.vue"),
    beforeEnter: authGuard,
    meta: { title: "Vehicle Management", back: "client-vehicle" },
  },
  {
    path: "/client-uploads",
    component: () => import("@/components/vehicle/ClientUploads.vue"),
    beforeEnter: authGuard,
    meta: { title: "Client Vehicle Uploads", back: "vehicle-management" },
  },
  {
    path: "/client-adds",
    component: () => import("@/components/vehicle/ClientAdds.vue"),
    beforeEnter: authGuard,
    meta: { title: "Client Vehicle Uploads" },
  },
  {
    path: "/client-logs",
    component: () => import("@/components/vehicle/EditLogs.vue"),
    beforeEnter: authGuard,
    meta: { title: "Client Vehicle Uploads" },
  },
  {
    path: "/tag-types",
    component: () => import("@/components/vehicle/TagTypes.vue"),
    beforeEnter: authGuard,
    meta: { title: "Tag Type Management" },
  },
  {
    path: "/toll-tags",
    component: () => import("@/components/vehicle/TollTags.vue"),
    beforeEnter: authGuard,
    meta: { title: "Deactivation Request Management" },
  },
  {
    path: "/carrier-deactivation",
    component: () => import("@/components/vehicle/carrierDeactivation.vue"),
    beforeEnter: authGuard,
    meta: { title: "Carrier deactivation request" },
  },
  {
    path: "/vehicle-upload",
    component: () => import("@/components/vehicle/VehicleUpload.vue"),
    beforeEnter: authGuard,
    meta: { title: "Multiple Vehicle Upload" },
  },
  {
    path: "/client-vehicle",
    component: () => import("@/components/vehicle/ClientsVehicles.vue"),
    beforeEnter: authGuard,
    meta: { title: "Vehicle Management" },
  },
  {
    path: "/vehicle-status",
    component: () => import("@/components/vehicle/VehicleStatus.vue"),
    beforeEnter: authGuard,
    meta: { title: "Vehicle Status" },
  },
  {
    path: "/states",
    component: () => import("@/components/vehicle/States.vue"),
    beforeEnter: authGuard,
    meta: { title: "States" },
  },
  {
    path: "/agencies",
    component: () => import("@/components/vehicle/Agencies.vue"),
    beforeEnter: authGuard,
    meta: { title: "Agencies" },
  },
  {
    path: "/amazon-vehicles",
    component: () => import("@/components/vehicle/AmazonVehicle.vue"),
    beforeEnter: authGuard,
    meta: { title: "Amazon Vehicles" },
  },

  //Unique Transaction management
  {
    path: "/unique-management",
    component: () => import("@/components/UniqueID/Transaction.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transactions Management", back: "client-transactions" },
  },

  {
    path: "/new-clients",
    component: () => import("@/components/UniqueID/ClientAdds.vue"),
    beforeEnter: authGuard,
    meta: { title: "New Clients" },
  },
  {
    path: "/transaction-logs",
    component: () => import("@/components/UniqueID/EditLogs.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transaction Logs" },
  },
  {
    path: "/transaction-upload",
    component: () => import("@/components/UniqueID/TransactionUpload.vue"),
    beforeEnter: authGuard,
    meta: { title: "Multiple transactions Upload" },
  },

  {
    path: "/unique-agencies",
    component: () => import("@/components/UniqueID/Agencies.vue"),
    beforeEnter: authGuard,
    meta: { title: "Agencies" },
  },
  {
    path: "/duplicate-transactions",
    component: () => import("@/components/UniqueID/DuplicateTransactions.vue"),
    beforeEnter: authGuard,
    meta: { title: "Duplicated Transactions" },
  },

  {
    path: "/clients-cl",
    component: () => import("@/components/UniqueID/cl1.vue"),
    beforeEnter: authGuard,
    meta: { title: "Confidence levels" },
  },
  {
    path: "/confidence-levels",
    component: () => import("@/components/UniqueID/cl2.vue"),
    beforeEnter: authGuard,
    meta: { title: "Confidence levels" },
  },
  {
    path: "/dashboard-uni",
    component: () => import("@/components/UniqueID/dashboard.vue"),
    beforeEnter: authGuard,
    meta: { title: "Dashboard" },
  },
  {
    path: "/business-rules",
    component: () => import("@/components/UniqueID/businessRules.vue"),
    beforeEnter: authGuard,
    meta: { title: "Business Rules" },
  },
  {
    path: "/complete-duplicate",
    component: () => import("@/components/UniqueID/CompleteDuplicate.vue"),
    beforeEnter: authGuard,
    meta: { title: "Complete Duplicates" },
  },

  {
    path: "/masterfile-upload",
    component: () => import("@/components/UniqueID/MasterfileUpload.vue"),
    beforeEnter: authGuard,
    meta: { title: "Vehicle Masterfile Upload" },
  },
  {
    path: "/file-track",
    component: () => import("@/components/UniqueID/Filetracking.vue"),
    beforeEnter: authGuard,
    meta: { title: "File Tracking Management" },
  },

  {
    path: "/glossary",
    component: () => import("@/components/UniqueID/glossary.vue"),
    beforeEnter: authGuard,
    meta: { title: "Glossary" },
  },
  //employees
  {
    path: "/system-users",
    component: () => import("@/components/employees/SystemUsers.vue"),
    beforeEnter: authGuard,
    meta: { title: "System Users" },
  },
  //employees

  // client View
  // {
  //   path: "/client-dashboard",
  //   component: () => import("@/components/clientView/dashboard.vue"),
  //   beforeEnter: authGuard,
  //   meta: { title: "Dashboard" },
  // },
  {
    path: "/client-lists",
    component: () => import("@/components/clientView/ClientLists.vue"),
    beforeEnter: authGuard,
    meta: { title: "Carriers List" },
  },
  {
    path: "/openroads-lists",
    component: () => import("@/components/openroads/ClientLists.vue"),
    beforeEnter: authGuard,
    meta: { title: "Open Roads Operators" },
  },
  {
    path: "/openroads-management",
    component: () => import("@/components/client/OpenroadsClients.vue"),
    beforeEnter: authGuard,
    meta: { title: "Operator Management" },
  },
  {
    path: "/openroads-profile",
    component: () => import("@/components/client/OpenroadsProfile.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients Management" },
  },
  {
    path: "/openroads-vehicles",
    component: () => import("@/components/vehicle/OpenroadsVehicle.vue"),
    beforeEnter: authGuard,
    meta: { title: "Vehicle Management", back: "client-vehicle" },
  },
  {
    path: "/openroads-tow-vehicles",
    component: () => import("@/components/vehicle/OpenroadsTowVehicle.vue"),
    beforeEnter: authGuard,
    meta: { title: "Vehicle Management", back: "client-vehicle" },
  },
  {
    path: "/openroads-signups",
    component: () => import("@/components/client/OpenroadsSignups.vue"),
    beforeEnter: authGuard,
    meta: { title: "Clients Management" },
  },
  {
    path: "/openroads-transactions",
    component: () =>
      import("@/components/transaction/OpenroadsTransactions.vue"),
    beforeEnter: authGuard,
    meta: { title: "Open Roads Transactions" },
  },
  {
    path: "/openroads-incoming-invoices",
    component: () =>
      import("@/components/transaction/OpenroadsIncomingInvoices.vue"),
    beforeEnter: authGuard,
    meta: { title: "Open Roads Transactions" },
  },
  {
    path: "/disputed-openroads-transactions",
    component: () =>
      import("@/components/transaction/DisputedOpenroadsTransactions.vue"),
    beforeEnter: authGuard,
    meta: { title: "Open Roads Disputed Transactions" },
  },
  {
    path: "/openroads-single-request",
    component: () =>
      import("@/components/transaction/OpenroadsSingleRequest.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transponder Request" },
  },
  {
    path: "/openroads-uploads",
    component: () => import("@/components/vehicle/OpenroadsFleetUploads.vue"),
    beforeEnter: authGuard,
    meta: { title: "Open Roads Fleet Uploads", back: "vehicle-management" },
  },
  {
    path: "/openroads-deactivation",
    component: () => import("@/components/vehicle/OpenroadsDeactivation.vue"),
    beforeEnter: authGuard,
    meta: { title: "Open Roads deactivation request" },
  },
  {
    path: "/openroads-tag-types",
    component: () => import("@/components/vehicle/OpenroadsTagTypes.vue"),
    beforeEnter: authGuard,
    meta: { title: "Tag Type Management" },
  },
  {
    path: "/openroads-states",
    component: () => import("@/components/vehicle/OpenroadsStates.vue"),
    beforeEnter: authGuard,
    meta: { title: "States" },
  },
  {
    path: "/openroads-card-details",
    component: () =>
      import("@/components/transaction/OpenroadsCardDetails.vue"),
    beforeEnter: authGuard,
    meta: { title: "Payment Card Management" },
  },

  {
    path: "/openroads-invoices",
    component: () => import("@/components/transaction/OpenroadsInvoices.vue"),
    beforeEnter: authGuard,
    meta: { title: "Open Roads Invoices Management" },
  },
  {
    path: "/openroads-citations",
    component: () => import("@/components/transaction/OpenroadsCitations.vue"),
    beforeEnter: authGuard,
    meta: { title: "Open Roads Citations Management" },
  },
  {
    path: "/openroads-dumps",
    component: () => import("@/components/transaction/OpenroadsDumps.vue"),
    beforeEnter: authGuard,
    meta: { title: "Open Roads Transaction Dumps" },
  },
  {
    path: "/openroads-failed-replenish",
    component: () => import("@/components/transaction/OpenroadsReplenish.vue"),
    beforeEnter: authGuard,
    meta: { title: "Failed Credit Card Replenish" },
  },
  {
    path: "/openroads-balance",
    component: () => import("@/components/transaction/OpenroadsAccounts.vue"),
    beforeEnter: authGuard,
    meta: { title: "Account Balance Management" },
  },
  {
    path: "/openroads-top-ups",
    component: () =>
      import("@/components/transaction/OpenroadsTopUpRequests.vue"),
    beforeEnter: authGuard,
    meta: { title: "Top Up Requests" },
  },

  {
    path: "/manage-accounts",
    component: () => import("@/components/manageaccounts/ManageAccounts.vue"),
    beforeEnter: authGuard,
    meta: { title: "Manage Accounts" },
  },
  {
    path: "/users-accounts",
    component: () => import("@/components/accounts/Accounts.vue"),
    beforeEnter: authGuard,
    meta: { title: "Manage Accounts" },
  },
  {
    path: "/tickets",
    component: () => import("@/components/ticketsystem/TicketSystem.vue"),
    beforeEnter: authGuard,
    meta: { title: "Ticket System", back: "ticket-dashboard" },
  },
  {
    path: "/tasks",
    component: () => import("@/components/ticketsystem/Tasks.vue"),
    beforeEnter: authGuard,
    meta: { title: "Tasks Management", back: "ticket-dashboard" },
  },
  {
    path: "/projects",
    component: () => import("@/components/ticketsystem/Projects.vue"),
    beforeEnter: authGuard,
    meta: { title: "Project Management", back: "ticket-dashboard" },
  },
  {
    path: "/ticket-dashboard",
    component: () => import("@/components/ticketsystem/TicketDashboard.vue"),
    beforeEnter: authGuard,
    meta: { title: "Ticket Dashboard", back: "projects" },
  },

  //HCTRA
  {
    path: "/dashboard-u",
    component: () => import("@/components/HCTRA-dashboard/dashboard.vue"),
    beforeEnter: authGuard,
    meta: { title: "Dashboard" },
  },

  {
    path: "/unique-transactions",
    component: () => import("@/components/HCTRA-dashboard/Transaction.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transactions Management" },
  },
  {
    path: "/transaction-logs",
    component: () => import("@/components/HCTRA-dashboard/EditLogs.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transaction Logs" },
  },
  {
    path: "/manage-vehicles",
    component: () => import("@/components/HCTRA-dashboard/cl1.vue"),
    beforeEnter: authGuard,
    meta: { title: "Manage Vehicles" },
  },
  {
    path: "/vehicle",
    component: () => import("@/components/HCTRA-dashboard/vehicles.vue"),
    beforeEnter: authGuard,
    meta: { title: "Vehicles" },
  },
  {
    path: "/unique-accounts",
    component: () => import("@/components/HCTRA-dashboard/AccountsManage.vue"),
    beforeEnter: authGuard,
    meta: { title: "Accounts" },
  },

  { path: "/index.html", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkExactActiveClass: "active",
});

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title =
    "Innovative - " +
    (toRoute.meta && toRoute.meta.title ? toRoute.meta.title : "");
  next();
});

export default router;
